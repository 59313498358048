<template>
  <div class="row  pagination-block">
    <div class="col-md-6 pagination-form-inline pagination-left-block" style="z-index: 99">
      <span>Showing {{ pageAll.from }} to {{pageAll.to }} of {{ pageAll.total_filtered }} {{role}}</span>
      <span v-if="filteredRowsCount < rowsCount" style="margin-left: 5px"> &#40;filtered from {{pageAll.total_unfiltered }} total {{role}}&#41;</span>
      <span style="margin:auto 5px;"> || </span>
      <span>Show Entries </span>
      <input style="width: 60px; display: inline-block" type="text" class="form-control" v-model="perPage2"  @keypress="onlyNumber" @input="updatePerPage(perPage2)"/>
    </div>
    <div class="col-md-6 pagination-right-block">
      <b-pagination align="right"
                    :total-rows="filteredRowsCount"
                    v-model="currentPage2"
                    @input="updateCurrentPage(currentPage2)"
                    :per-page="computedPerPage"></b-pagination>
    </div>
  </div>
</template>

<script>
import {BPagination} from 'bootstrap-vue'
export default {
  name: "block-pagination",
  components: {
    BPagination
  },
  data() {
    return {
      currentPage2: this.currentPage,
      perPage2: this.perPage,
    }
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    perPageForMultipleTables: {
      type: Number,
      default: 0,
    },
    countFiltered: {
      type: Number,
      default() {
        return 0
      },
    },
    count: {
      type: Number,
      default() {
        return 0
      },
    }
  },
  methods: {
    onlyNumber (event) {
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 32 && keyCode !== 45 && keyCode !== 43 && keyCode !== 40 && keyCode !== 41) {
        event.preventDefault();
      }
    },
    updatePerPage(value) {
      this.$emit('changedPerPage', Number(value))
    },
    updateCurrentPage(value) {
      this.$emit('changedCurrentPage', Number(value))
    },
    updateCustomersPerPage(perPage) {
      if (perPage < 1 || perPage > 1000) {
        this.perPage2 = 50;
      } else {
        this.perPage2 = perPage;
      }
    },
  },
  computed: {
    computedPerPage: {
      get() {
        return this.perPage2;
      },
      set(v) {
        this.debouncedUpdateCustomersPerPage(v);
      }
    },
    rowsCount: function () {
      return this.count
    },
    filteredRowsCount: function () {
      return this.countFiltered
    },
    pageAll: function () {
      let status = [];
      if (this.filteredRowsCount > 0) {
        status["from"] = (this.currentPage2 - 1) * this.computedPerPage + 1;
        status["to"] = this.currentPage2 * this.computedPerPage;
      } else {
        status["from"] = 0;
        status["to"] = 0;
      }

      if (this.filteredRowsCount % this.computedPerPage > 0 && this.currentPage2  > this.filteredRowsCount / this.computedPerPage) {
        status["to"] = this.filteredRowsCount;
      }

      status["total_filtered"] = this.filteredRowsCount;
      status["total_unfiltered"] = this.rowsCount;

      return status;

    },
  },
  watch: {
    countFiltered: function() {
      if (this.countFiltered < this.count) {
        this.updateCurrentPage(1)
        this.currentPage2 = 1;
      }
    },
    perPageForMultipleTables: function(value) {
      this.perPage2 = value;
    }
  },
  created() {
    this.debouncedUpdateCustomersPerPage = _.debounce(function (v) {
      this.updateCustomersPerPage(v);
    }, 700);
  }
}
</script>

<style scoped>

</style>
